import { Device } from './useDevice'

const capitalizeFirstLetter = (str:string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const getResponsiveProp = (device: Device, props: any, propName: string) => {
  const capitalizedPropName = capitalizeFirstLetter(propName)
  const desktop = props[propName]
  const smallScreen = props[`smallScreen${capitalizedPropName}`]
  const tablet = props[`tablet${capitalizedPropName}`]
  const phone = props[`phone${capitalizedPropName}`]

  if (device === 'phone' && phone) {
    return phone
  }
  if (device === 'tablet' && tablet) {
    return tablet
  }
  if (device === 'smallScreen' && smallScreen) {
    return smallScreen
  }
  return desktop
}

export default getResponsiveProp
