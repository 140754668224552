import { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './MenuDropdown.module.css'
import * as Icon from 'react-feather'

type DropdownMenuItem = {
  id: number;
  name: string;
  url: string;
}

type MenuDropdownProps = {
  name: string,
  items: DropdownMenuItem[]
}

const MenuDropdown = (props: MenuDropdownProps) => {
  const location = useLocation()
  const [expanded, setExpanded] = useState(false)

  const buttonEl = useRef(null)

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        setExpanded(false)
        if (buttonEl.current) {
          (buttonEl.current as HTMLElement).blur()
        }
      }
    }
    const handleClick = (e: any) => {
      setExpanded(false)
    }
    window.addEventListener('keydown', handleEsc)
    document.addEventListener('mousedown', handleClick)

    return () => {
      window.removeEventListener('keydown', handleEsc)
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  const handleButtonClick = (e: any) => {
    e.preventDefault()
    setExpanded(!expanded)
  }
  const handleLinkMouseDown = (e: any, url: string) => {
    if (url.startsWith('http')) {
      window.open(url, '_blank', 'noreferrer')
      setExpanded(false)
    }
    if (url !== location.pathname) {
      e.stopPropagation()
    }
  }

  const elProps: any = {
    className: styles['menu-dropdown']
  }

  const buttonProps: any = {
    ref: buttonEl,
    onClick: handleButtonClick,
    className: styles['menu-dropdown__button']
  }

  const dropdownProps: any = {
    className: styles['menu-dropdown__dropdown']
  }

  return (
    <div {...elProps}>
      <button {...buttonProps}>
        {props.name}
        <Icon.ChevronDown width={18} />
      </button>
      {expanded &&
        <div {...dropdownProps}>
          {props.items.map((item: DropdownMenuItem) => {
            return (
              <Link
                onMouseDown={(e: any) => handleLinkMouseDown(e, item.url)}
                key={item.id}
                className={styles['menu-dropdown__link'] + ((item.url === location.pathname) ? ' ' + styles['menu-dropdown__link--current'] : '')}
                to={item.url}
              >
                {item.name}
              </Link>
            )
          })}
        </div>
      }
    </div>
  )
}

export default MenuDropdown
