import { useEffect, useState } from 'react'

type Device = 'desktop' | 'smallScreen' | 'tablet' | 'phone'

const useDevice = () => {
  const [device, setDevice] = useState<Device>('desktop')

  const handleChange = () => {
    const widths = [window.innerWidth]

    if (window.screen?.width) {
      widths.push(window.screen?.width)
    }

    const width = Math.min(...widths)

    if (width <= 480) {
      // Mobile devices
      setDevice('phone')
      return
    }
    if (width <= 768) {
      // iPads, Tablets
      setDevice('tablet')
      return
    }
    if (width <= 1024) {
      // Small screens, laptops
      setDevice('smallScreen')
      return
    }
    if (width < 1200) {
      // Desktops, large screens
      setDevice('desktop')
      return
    }
    // Extra large screens, TV
    setDevice('desktop')
  }

  useEffect(() => {
    window.addEventListener('resize', handleChange)
    window.addEventListener('orientationchange', handleChange)
    window.addEventListener('load', handleChange)
    window.addEventListener('reload', handleChange)
    handleChange()
    return () => {
      window.removeEventListener('resize', handleChange)
      window.removeEventListener('orientationchange', handleChange)
      window.removeEventListener('load', handleChange)
      window.removeEventListener('reload', handleChange)
    }
  })

  return device
}

export default useDevice
export type { Device }
