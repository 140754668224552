const nbspacer = (str: string) => {
  const noNpspStr = str.replace(/&nbsp;/g, ' ').replace(/\u00A0/g, ' ')
  const parts = noNpspStr.split(' ')
  const result = []
  for (const part of parts) {
    result.push(part)
    if (part.length === 1 || part.length === 2) {
      result.push('\u00A0')
    } else {
      result.push(' ')
    }
  }
  return result.join('')
}

export default nbspacer
